/**
 * 해당 경로가 상세 페이지 경로인지 확인
 * @param str 페이지 경로
 * @param listPaths 리스트 페이지 경로 리스트
 * @returns 상세 페이지 경로 여부
 */
export const checkDetailPagePath = (str: string, listPaths: string[]) => {
  const regex = new RegExp(`^(${listPaths.join('|')})\/(\\d+)(?:/)?$`)
  return regex.test(str)
}
