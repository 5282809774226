'use client'

import GnbComponent from '@/components/Gnb'

/**
 * GNB 컴포넌트
 */
const Gnb = () => {
  return <GnbComponent />
}

export default Gnb
