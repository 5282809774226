import { NBT, RCODE } from '@/consts'
import { setSessionStorage } from '@/utils'

/**
 * 쿼리 파라미터를 세션 스토리지에 저장한다.
 * rcode: 추천인 코드
 * nbt: nbt 리워드 캠페인
 */
const storeQueryInSession = () => {
  const params = new URLSearchParams(window.location.search)
  const rcode = params.get('rcode')
  const nbt = params.get('click_key')
  if (rcode) setSessionStorage(RCODE, Array.isArray(rcode) ? rcode[0] : rcode)
  if (nbt) setSessionStorage(NBT, Array.isArray(nbt) ? nbt[0] : nbt)
}

export default storeQueryInSession
