import { z } from 'zod'
import { API_PATH, MOBILE_EVENTS } from '@/consts'
import { postMessageToApp } from '@/utils'
import { handleError, handleRequest } from '@/utils/httpClient'

const responseSchema = z.object({
  success: z.boolean(),
})
export type NotificationReadResponse = z.infer<typeof responseSchema>

/* 알림 클릭 시 읽음 처리 */
export const handleNotificationRead = async (ids: number[]) => {
  // 읽지 않은 알림이 없으면 바로 리턴
  if (!ids.length) return Promise.resolve()

  try {
    return await handleRequest<NotificationReadResponse>(
      API_PATH.MyNoticesRead,
      {
        method: 'post',
        json: {
          ids,
        },
      },
    ).then(() => {
      // 웹에서 읽음 처리 후, 앱에서도 읽음 처리
      postMessageToApp(MOBILE_EVENTS.APP_CANCEL_NOTIFICATION, {
        ids,
      })
    })
  } catch (error) {
    handleError(error)
  }
}
