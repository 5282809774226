import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import sendPageViewEvent from '@/utils/sendPageViewEvent'

/**
 * 라우트 변경을 감지하는 hook
 */
const useRouteChange = () => {
  const pathname = usePathname()
  const previousPathname = useRef(pathname)

  useEffect(() => {
    const currentPathname = pathname

    // 경로가 변경되었을 때만 콜백 실행
    if (previousPathname.current !== currentPathname) {
      // route 변경 시, GTM 페이지뷰 이벤트 전송
      sendPageViewEvent()
      previousPathname.current = currentPathname
    }
  }, [pathname])
}

export default useRouteChange
