import useSWR from 'swr'
import { z } from 'zod'
import { API_PATH } from '@/consts'

const featureFlagSchema = z.record(z.boolean())
export type FeatureFlag = z.infer<typeof featureFlagSchema>

const useFeatureFlag = <T extends FeatureFlag>() => {
  const { data } = useSWR<T>(API_PATH.FeatureFlag)
  return data
}

export default useFeatureFlag
