import { ReactNode } from 'react'
import clsx from 'clsx'
import { useToaster } from 'react-hot-toast/headless'
import type { Toast as ToastType } from 'react-hot-toast/headless'

const DEFAULT_WIDTH = 432

const Toast = ({ toast, width }: { toast: ToastType; width: number }) => {
  return (
    <div
      key={toast.id}
      className={clsx(
        'w-full',
        'flex',
        'fixed',
        'justify-center',
        'v1-mobile:px-6',
      )}
      style={{
        bottom: toast?.style?.bottom,
        zIndex: toast?.style?.zIndex ?? 10,
      }}
    >
      <div
        className={clsx(
          'min-h-[56px]',
          'rounded-2xl',
          'bg-grey-900',
          'text-white',
          'p-4',
          'ease-out',
          //fadeout의 시간을 1s미만으로 하게 되면 toast.visible할 때 3s인 경우 깜빡이는 현상이 있어서 1s로 설정하였습니다.
          //TODO: toastFadeIn과 toastFadeOut을 스크립트로 생성하여, toast 요소의 style.bottom 값에 따라 변경할 수 있도록 수정이 필요합니다.
          toast.visible
            ? 'animate-[toastFadeIn_0.5s]'
            : 'animate-[toastFadeOut_1s]',
        )}
        {...toast.ariaProps}
        style={{
          width: width ?? DEFAULT_WIDTH,
        }}
      >
        {toast.message as ReactNode}
      </div>
    </div>
  )
}

const Toaster = () => {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause } = handlers

  const width =
    typeof toasts[0]?.style?.width === 'number'
      ? toasts[0]?.style?.width
      : DEFAULT_WIDTH

  return (
    <div onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((toast) => {
        return <Toast key={toast.id} toast={toast} width={width} />
      })}
    </div>
  )
}

export default Toaster
