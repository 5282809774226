import useMyInfo from '@/hooks/useMyInfo'

export const Form1 = () => {
  const { myInfo: { user_id } = {} } = useMyInfo()

  return (
    <div className="absolute inset-x-0 bottom-0 top-0">
      <iframe
        src={`https://ip9vbm9vehl.typeform.com/to/RtgFT826#user_id=${user_id}`}
        className="w-full h-full"
      />
    </div>
  )
}

export const Form2 = () => {
  const { myInfo: { user_id } = {} } = useMyInfo()

  return (
    <div className="absolute inset-x-0 bottom-0 top-0">
      <iframe
        src={`https://ip9vbm9vehl.typeform.com/to/ER4pTSXm#user_id=${user_id}`}
        className="w-full h-full"
      />
    </div>
  )
}
