import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import usePageView from '@/hooks/usePageView'
import useScrollEvent from '../useScrollEvent'

/**
 * GTM 초기화 및 유저 아이디 전달
 */
const useGTMInit = () => {
  // GTM 초기화
  useEffect(() => {
    TagManager.initialize({
      gtmId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`,
    })
  }, [])

  // 페이지 변경시 GTM 페이지뷰 이벤트 전송
  usePageView()
  // 특정 영역만큼 스크롤 내렸을때 GTM 이벤트 전송
  useScrollEvent()
}

export default useGTMInit
