import { useEffect, useRef } from 'react'
import { useSearchParams, usePathname } from 'next/navigation'
import { useSetAtom } from 'jotai'
import { URL_PATH } from '@/consts'
import { mainScrollTopAtom } from '@/stores'

// 추천리스트 상세 페이지 경로
const VIEW_PAGE_PATHS = [
  URL_PATH.Boards, // 질문과 답변
  URL_PATH.GuidesBreast, // 주제별 암 정보: 유방암
  URL_PATH.GuidesStomach, // 주제별 암 정보: 위암
  URL_PATH.GuidesThyroid, // 주제별 암 정보: 갑상선암
  URL_PATH.GuidesColon, // 주제별 암 정보: 대장암
  URL_PATH.GuidesLung, // 주제별 암 정보: 폐암
  URL_PATH.ContentsList, // 더보기 추천리스트: 상세페이지는 아니지만 뒤로가기 시 스크롤 유지 필요.
]

const MAIN_PATH_NAME = /^\/($|\?|#)/

/**
 * 추천리스트 상세 페이지인지 확인
 * @param currentPath 현재 경로
 */
const checkIsViewPage = (currentPath: string) => {
  return VIEW_PAGE_PATHS.some((path) => {
    const regex = new RegExp(`^${path}/\\d+.*$`)
    return (
      regex.test(currentPath) || currentPath.startsWith(URL_PATH.ContentsList)
    )
  })
}

/**
 * 메인 페이지의 추천리스트 스크롤 위치를 조건에 따라 초기화 하는 커스텀 훅
 * 메인 페이지의 추천리스트에서 상세 페이지로 이동 후 브라우저 뒤로가기를 통해 메인 페이지로 돌아왔을 때 jotai atom으로 스크롤 위치를 유지하고 있다.
 * 추천리스트 상세 페이지에서 메인 페이지가 아닌 다른 페이지로 이동하면 스크롤 위치를 초기화 한다.
 */
const useResetMainScrollPosition = () => {
  const fromPathname = useRef('')
  const setMainScrollTop = useSetAtom(mainScrollTopAtom)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const previousPathname = useRef(pathname + (searchParams?.toString() || ''))

  useEffect(() => {
    const currentPathWithParams =
      pathname + (searchParams?.toString() ? '?' + searchParams.toString() : '')

    const handleRouteChangeStart = () => {
      if (!!fromPathname.current && !!pathname) fromPathname.current = pathname
    }
    // Trigger after the route change completes
    const handleRouteChangeComplete = (nextPathname: string) => {
      // 이전 페이지가 추천리스트 상세 페이지인지 확인
      const isFromViewPage = checkIsViewPage(fromPathname.current)
      // 다음 페이지가 메인 페이지인지 확인
      const isNextMainPage = MAIN_PATH_NAME.test(nextPathname)

      // 추천리스트 메인 리스트에서 mainScrollTopAtom을 사용하여 스크롤 위치를 유지하고 있다.
      // 추천리스트 메인 리스트에서 추천리스트 상세 링크를 클릭할 때  mainScrollTopAtom을 설정한다.

      // mainScrollTop은 다음 조건에 리셋된다: 브라우저 뒤로가기로 메인화면에 진입하지 않음.
      if (
        // 1. 추천리스트 상세 ->  non 메인 추천리스트
        (isFromViewPage && !isNextMainPage) ||
        //  2. non 추천리스트 상세 ->  메인 추천리스트
        (!isFromViewPage && isNextMainPage) ||
        //  3. 메인 추천리스트 ->  non 추천리스트 상세
        (MAIN_PATH_NAME.test(fromPathname.current) &&
          !checkIsViewPage(nextPathname))
      ) {
        setMainScrollTop(0)
      }
      // Update previous pathname after route change completes
      previousPathname.current = currentPathWithParams
    }

    // Simulate `routeChangeStart`
    if (previousPathname.current !== currentPathWithParams) {
      handleRouteChangeStart() // Trigger at the start of the route change
    }

    // Wait for pathname and searchParams to stabilize, then trigger routeChangeComplete
    const timeoutId = setTimeout(handleRouteChangeComplete, 100)

    return () => clearTimeout(timeoutId)
  }, [pathname, searchParams, setMainScrollTop])
}

export default useResetMainScrollPosition
