import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { reportBannerAtom, INITIAL_REPORT_BANNER } from '@/stores'

/**
 * 앱 시작 시, 리포트 업데이트 안내 배너를 노출하기 위해,
 * 로컬 스토리지에 저장된 건강정보 업데이트 여부 및 세션 스토리지에 저장된 리포트 배너 노출 여부를 확인한다.
 */
export const useReportBanner = () => {
  const [{ healthInfoUpdateStatus, reportBannerShown }, setReportBanner] =
    useAtom(reportBannerAtom)

  useEffect(() => {
    // 건강정보 업데이트가 되었는데, 리포트 배너가 노출상태가 아니라면 노출상태로 변경
    // 건강정보 업데이트 후, 첫 새 세션이다.
    if (healthInfoUpdateStatus === 'updated' && !reportBannerShown) {
      setReportBanner(() => ({
        healthInfoUpdateStatus: 'checked',
        reportBannerShown: true,
      }))
      // 건강정보 업데이트가 완료되었는데, 리포트 배너가 노출상태라면 노출상태 해제
      // 건강정보 업데이트 후, 두 번째 세션이다.
    } else if (healthInfoUpdateStatus === 'checked' && !reportBannerShown) {
      setReportBanner(() => ({
        healthInfoUpdateStatus: INITIAL_REPORT_BANNER.healthInfoUpdateStatus,
        reportBannerShown: INITIAL_REPORT_BANNER.reportBannerShown,
      }))
    }
  }, [setReportBanner, healthInfoUpdateStatus, reportBannerShown])
}

export default useReportBanner
