import { useAtomDevtools } from 'jotai-devtools'
import {
  viewedBoardIdsAtom,
  activeMidGuideAtom,
  mainAtom,
  mainScrollTopAtom,
  mainBottomSheetAtom,
} from '@/stores'

const DEV_ENVS = ['mock', 'develop']
const isDev = DEV_ENVS.includes(String(process.env.NEXT_PUBLIC_ENV))

export const useJotaiDevtools =
  isDev && typeof window !== 'undefined'
    ? () => {
        useAtomDevtools(activeMidGuideAtom, { name: 'activeMidGuide' })
        useAtomDevtools(viewedBoardIdsAtom, { name: 'viewedBoardIds' })
        useAtomDevtools(mainAtom, { name: 'main' })
        useAtomDevtools(mainScrollTopAtom, { name: 'mainScrollTop' })
        useAtomDevtools(mainBottomSheetAtom, { name: 'mainBottomSheet' })
      }
    : () => {}
