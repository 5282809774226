import * as Sentry from '@sentry/nextjs'
import { API_PATH } from '@/consts'
import type { MyInfo as MyInfoResponse } from '@/hooks/useMyInfo'
import { handleCustomError, handleRequest } from '@/utils/httpClient'
import { getAuth, logout } from '.'

/**
 * 내 계정 정보를 useMyInfo 훅 대신, 필요할 때만 요청한다.
 * 헤더와 같이 어디에나 있는 컴퍼넌트에 useMyHook을 사용하면, 불필요한 요청이 발생할 수 있다.
 * auth/index에 넣지 않고, 따로 분리한 이유는 API 요청으로 인해 app router에서 에러가 발생하기 때문.
 */
export const getMyInfo = async () => {
  const { isLoggedIn } = getAuth()
  if (!isLoggedIn) return Promise.resolve(null)

  try {
    const myInfo = await handleRequest<MyInfoResponse>(API_PATH.MyInfo, {
      method: 'GET',
    })
    // Sentry에 유저 아이디 전달
    Sentry.setUser({ id: myInfo.user_id })
    return myInfo
  } catch (error) {
    logout()
    await handleCustomError({ error, ignoreCodes: [400, 401] })
    return Promise.resolve(null)
  }
}
