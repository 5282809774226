import useFeatureFlag from '@/hooks/useFeatureFlag'

/**
 * 앱 회원탈퇴 기능 변경됨에 따라, 회원탈퇴 가능 앱버전을 고정시켰다. REAUTH_FOR_APP_UNREGISTER_VERSION
 * iOS와 aOS가 각각 스토어에 게시되면, 플래그를 활성화 하여 사용자에게 앱 업데이트 안내 팝업을 띄운다.
 * 현재 피처플래그는 APP_INSTALL_BANNER만 존재.
 * APP_INSTALL_BANNER 이름으로 혼란스러울 수 있으니, 이 커스텀 hook에 로직을 숨긴다.
 * TODO: 앱이 스토어에 게시되면 일괄 삭제한다.
 */
const useAppUpdateFlag = () => {
  const featureFlag = useFeatureFlag<{ APP_INSTALL_BANNER: boolean }>()
  return featureFlag?.APP_INSTALL_BANNER ?? false
}

export default useAppUpdateFlag
