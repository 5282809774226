// TODO: modal이 dependency에 들어가면 무한 루프가 발생한다. 개선 필요.
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useSearchParams, usePathname } from 'next/navigation'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'

/**
 * 쿼리 스트링에서 actionType을 제거한다.
 * @param pathname
 */
const deleteQueryParams = (pathname: string) => {
  // 현재 URL에서 쿼리 파라미터와 해시를 유지하면서 actionType만 제거
  const params = new URLSearchParams(window.location.search)
  params.delete('actionType') // actionType 파라미터만 제거
  const newUrl = `${pathname}?${params.toString()}${window.location.hash}`
  history.replaceState(null, '', `${newUrl}`)
}

/**
 * 전역에서 알림(alert, modal)을 사용하기 위한 hook
 */
const useGlobalAlert = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { showCreateBoardModal } = useCreateBoardModal()
  const actionType = searchParams?.get('actionType')

  // 질문하기 버튼을 통해 로그인 했을 때, 질문하기 모달을 보여준다.
  useEffect(() => {
    if (actionType !== 'board') return

    showCreateBoardModal()
    deleteQueryParams(pathname)
  }, [actionType, pathname])
}

export default useGlobalAlert
