import { MutableRefObject, useEffect, useRef } from 'react'
import TagManager from 'react-gtm-module'
import { usePathname } from 'next/navigation'

const SCROLL_PERCENTAGE = 90

function handleScroll(isEventExecuted: MutableRefObject<boolean>) {
  if (isEventExecuted.current) return
  // 현재 스크롤 위치
  const scrollPosition = window.scrollY || document.documentElement.scrollTop
  // document 전체 높이
  const documentHeight = document.documentElement.scrollHeight
  // 뷰포트 높이
  const viewportHeight = window.innerHeight
  // 현재 스크롤된 비율
  const scrollPercentage =
    (scrollPosition / (documentHeight - viewportHeight)) * 100

  if (scrollPercentage >= SCROLL_PERCENTAGE) {
    isEventExecuted.current = true
    TagManager.dataLayer({
      dataLayer: {
        event: 'scroll',
      },
    })
  }
}

/** 페이지를 스크롤함에 따라 이벤트를 전송하는 커스텀 훅 */
const useScrollEvent = () => {
  const pathname = usePathname()
  const isEventExecuted = useRef(false) // 페이지에서 한 번만 트리거하도록 체크하는 플래그

  useEffect(() => {
    window.addEventListener('scroll', () => {
      handleScroll(isEventExecuted)
    })

    return () => {
      window.removeEventListener('scroll', () => {
        handleScroll(isEventExecuted)
      })
    }
  }, [isEventExecuted])

  // 페이지 변경 시 값을 초기화해준다
  useEffect(() => {
    isEventExecuted.current = false
  }, [pathname])
}

export default useScrollEvent
