import { z } from 'zod'
import { CANCER_FILTERS, KOR_CANCERS } from '@/consts'

export const cancerObjectSchema = z.object({
  name: z.string(),
  parent: z.nullable(z.string()),
  is_major: z.boolean(),
})

// 암종 필터 영문명
export const cancerFilterSchema = z.enum(CANCER_FILTERS)
// 암종 필터 한글명
export const cancerKorFilterSchema = z.enum(['전체', ...KOR_CANCERS])

export type CancerObject = z.infer<typeof cancerObjectSchema>
export type CancerFilter = z.infer<typeof cancerFilterSchema>
export type CancerType = 'breast' | 'thyroid' | 'stomach' | 'lung' | 'colon'
