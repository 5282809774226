import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import * as Sentry from '@sentry/nextjs'
import { useAtomValue } from 'jotai'
import { CHANNEL_TALK_PAGES } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { gnbOpenAtom } from '@/stores'
import { isWebview } from '@/utils'

const useChannelService = () => {
  const pathname = usePathname()
  const { isLoggedIn } = useAuth()
  const isChannelTalkOpened = CHANNEL_TALK_PAGES[pathname ?? ''] !== undefined
  const pluginKey = `${process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY}`
  const isApp = isWebview()
  const isGnbOpen = useAtomValue(gnbOpenAtom)

  useEffect(() => {
    ChannelService.loadScript()
  }, [])

  useEffect(() => {
    ChannelService.boot(
      {
        pluginKey: pluginKey,
        profile: {
          name: '고객',
        },
        hidePopup: !isChannelTalkOpened,
        hideChannelButtonOnBoot: true,
      },
      () => {
        // 로그인이 되어있지 않거나, Gnb 메뉴가 열려있는 경우 채널톡을 열지 않는다.
        if (!isLoggedIn || isGnbOpen) return
        // 마이페이지에서만 채널톡 버튼을 보여준다.
        if (isChannelTalkOpened) {
          ChannelService.showChannelButton()

          setTimeout(() => {
            if (!isApp) return
            const shadowHost = document.querySelector('#ch-plugin-entry > div')
            const shadowRoot = shadowHost?.shadowRoot
            const targetElement = shadowRoot?.querySelector(
              '[data-ch-testid="launcher"]',
            )
            if (targetElement)
              // 탭 높이 52px + 버튼 bottom 위치 14px => 66px
              (targetElement as HTMLElement).style.bottom = '66px'
            // 채널톡 버튼을 못찾으면 Sentry에 에러를 기록한다.
            else
              Sentry.captureException(
                new Error('채널톡 버튼을 찾을 수 없습니다.'),
              )
          }, 0)
          return
        }
        // 위 경우 외에는 채널톡을 노출하지 않는다.
        ChannelService.hideChannelButton()
      },
    )
  }, [isLoggedIn, pluginKey, isChannelTalkOpened, pathname, isApp, isGnbOpen])
}

export default useChannelService
