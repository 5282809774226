import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { getAuth } from '@/utils/auth'
import { getMyInfo } from '@/utils/auth/getMyInfo'
import sendPageViewEvent from '@/utils/sendPageViewEvent'

const handleFirstRouteChange = () => {
  const { isLoggedIn } = getAuth()
  // 비로그인 유저의 경우, 페이지뷰 이벤트 바로 전송
  if (!isLoggedIn) {
    sendPageViewEvent()
    return
  }

  // 로그인 유저의 경우는, 유저 아이디를 전송한 후, 페이지뷰 이벤트 전송
  getMyInfo()
    .then((myInfo) => {
      if (!myInfo) return

      // GTM에 유저 아이디 전달
      TagManager.dataLayer({
        dataLayer: {
          userId: myInfo.user_id,
        },
      })
    })
    .finally(() => {
      sendPageViewEvent()
    })
}

/**
 * GTM에 페이지뷰 이벤트 전송하는 커스텀 훅
 * 첫 페이지 로딩 시 호출
 */
const usePageView = () => {
  useEffect(() => {
    handleFirstRouteChange()
  }, [])
}

export default usePageView
