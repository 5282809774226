import NiceModal from '@ebay/nice-modal-react'
import FullScreenModal from '@/hooks/useModal/FullScreenModal'
import { getAuth } from '@/utils/auth'
import { removeQueryString } from '@/utils/url'
import { Form1, Form2 } from './Modals'

const showPlusForm = (eventParam: string) => {
  // TODO: 로그인을 하지 않은 상태에서 이게 호출되면 아무런 동작을 하지 않는 것처럼 보인다.
  if (!getAuth().isLoggedIn || !eventParam) return

  const url = removeQueryString('event')
  history.replaceState(null, '', url)

  NiceModal.show(FullScreenModal, {
    title: '플러스 상담 체험 신청',
    children: eventParam === 'plusForm1' ? <Form1 /> : <Form2 />,
    scrollable: false,
  })
}

export default showPlusForm
