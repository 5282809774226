import TagManager from 'react-gtm-module'
import { URL_PATH } from '@/consts'
import { base64ToId, getPlatformFromCookie } from '@/utils'

/**
 * 페이지 주소의 pathname으로 컨텐츠 타입을 확인한다.
 * @returns 컨텐츠 타입
 */
const checkContentType = () => {
  // 질문과 답변 페이지
  if (window.location.pathname.startsWith(URL_PATH.Boards)) return 'question'
  // 주제별 암 정보 페이지
  if (window.location.pathname.startsWith(URL_PATH.HospitalsMain))
    return 'hospital_info'

  // 병원정보 페이지
  const isLibraryPage = [
    URL_PATH.GuidesBreast,
    URL_PATH.GuidesStomach,
    URL_PATH.GuidesThyroid,
    URL_PATH.GuidesLung,
    URL_PATH.GuidesLife,
    URL_PATH.GuidesColon,
  ].some((libraryPath) => window.location.pathname.startsWith(libraryPath))

  if (isLibraryPage) {
    return 'cancer_content'
  }
  return ''
}

/**
 * 모바일 앱에서 웹뷰에 설정한 user agent를 통해 앱 플랫폼을 확인한다.
 * @returns 'web' | 'android' | 'ios'.  react-native의 Platform.OS에는 'windows' | 'macos' 도 있기는 함.
 */
const getAppPlatform = () => {
  if (typeof window === 'undefined') return 'web'
  return getPlatformFromCookie() ?? 'web'
}

/**
 * 페이지뷰 이벤트를 GTM에 전송한다.
 */
const sendPageViewEvent = () => {
  const contentType = checkContentType()
  // 페이지 URL 경로로 부터 암종명을 얻을 수 있는 경우는 라이브러리 페이지 뿐이다.
  const cancerType =
    contentType === 'cancer_content'
      ? window.location.pathname.split('/')[1]
      : ''

  const searchParams = new URLSearchParams(window.location.search)
  const id = searchParams.get('id')

  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      ...(id && {
        contents_id: contentType === 'hospital_info' ? base64ToId(id) : id,
      }),
      ...(contentType && { contents_type: contentType }),
      ...(cancerType && { cancertype: cancerType.toUpperCase() }),
      user_source_platform: getAppPlatform(),
    },
  })
}

export default sendPageViewEvent
