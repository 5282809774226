import clsx from 'clsx'

const Loading = () => {
  return (
    <div
      className={clsx(
        'relative',
        'w-full',
        'h-0.5',
        'overflow-hidden',
        'before:absolute',
        'before:left-[-30%]',
        'before:w-1/4',
        'before:h-0.5',
        'before:bg-blue-100',
        'before:animate-loader',
      )}
    />
  )
}

export default Loading
